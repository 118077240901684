import React from 'react';
import {AnimatePresence} from 'framer-motion';

export const wrapPageElement = ({element}) => (
    <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
        document
            .body
            .classList
            .remove('overflow-hidden')
    }}>{element}</AnimatePresence>
);

const transitionDelay = 500;

export const shouldUpdateScroll = ({routerProps: {
        location
    }, getSavedScrollPosition}) => {
    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    } else {
        const savedPosition = getSavedScrollPosition(location);
        window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay);
    }
    return false;
};